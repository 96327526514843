import { useTranslation } from 'react-i18next';

import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value as string;
    i18n.changeLanguage(newLanguage);
  };

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français' }
  ];

  return (
    <Select
      value={currentLanguage}
      onChange={handleLanguageChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Select language' }}
      sx={{ marginBottom: 4, minWidth: 158, height: 40, borderRadius: 16 }}
      IconComponent={(props) => (
        <LanguageIcon {...props} sx={{ color: '#007BFF' }} />
      )}
    >
      {languages.map((lang) => (
        <MenuItem key={lang.code} value={lang.code}>
          {lang.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelector;
