import { initializeApp } from "firebase/app";

import { firebaseConfig } from "./firebase.config";
import {
  getAuth,

  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
// if (process.env.NODE_ENV !== "production") {
//   connectAuthEmulator(auth, "http://localhost:9099");
// }
export const db = getFirestore(Firebase);
export const storage = getStorage(Firebase);

export const Providers = { google: new GoogleAuthProvider() };
