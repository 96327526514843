import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Alert, AlertColor, Snackbar } from '@mui/material';

interface ToastsContextResult {
  success: (msg: string) => void;
  error: (msg: string) => void;
  warning: (msg: string) => void;
  info: (msg: string) => void;
}

const ToastsContext = createContext<ToastsContextResult>({
  success: () => { },
  error: () => { },
  warning: () => { },
  info: () => { },
});

interface Props {
  children?: React.ReactNode;
}

export const useAppToast = () => {
  return useContext(ToastsContext);
};

const ToastsProvider = (props: Props) => {
  const { children } = props;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const toast = useCallback((msg: string, sev: AlertColor) => {
    setSeverity(sev);
    setMessage(msg);
    setOpen(true);
  }, []);

  const value = useMemo(
    () => ({
      success: (msg: string) => toast(msg, 'success'),
      error: (msg: string) => toast(msg, 'error'),
      warning: (msg: string) => toast(msg, 'warning'),
      info: (msg: string) => toast(msg, 'info'),
    }),
    [toast],
  );

  return (
    <ToastsContext.Provider value={value}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={severity} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </ToastsContext.Provider>
  );
};

export default ToastsProvider;
