export const firebaseConfig = {
  apiKey: "AIzaSyCGWLKJSs_npStV3zaybu5BQ2L1EIcL5qA",
  authDomain: "lnfound-146b3.firebaseapp.com",
  databaseURL:
    "https://lnfound-146b3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lnfound-146b3",
  storageBucket: "lnfound-146b3.appspot.com",
  messagingSenderId: "477972900441",
  appId: "1:477972900441:web:ef3ddc28e10ea85eae5f48",
};
