import { useEffect, useState } from "react";

import { auth } from "../config/firebase";

export default function useAuth() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const isLoggedIn = () => !loading && user !== null;

  return { user, loading, isLoggedIn };
}