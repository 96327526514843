import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as yup from "yup";
import { signInAnonymously, signOut } from "firebase/auth";

import { Typography, Button, TextField, Box } from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Socials from "../components/utils/Socials";
import { ReactComponent as Scan } from "../assets/Scan.svg";
import { auth } from "../config/firebase";
import i18n from "../i18nconfig";
import LanguageSelector from "../components/utils/LanguageSelector";

const Home = () => {
  const [isNotLost, setIsNotLost] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  //@ts-ignore
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: "#fff",
                "&.Mui-disabled": {
                  backgroundColor: "#e4e4e4",
                },
              },
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                marginTop: 5,
                marginLeft: 0,
                fontWeight: 600,
              },
            },
          },
        },
      }),
    []
  );

  const validationSchema = yup.object({
    code: yup
      .string()
      .matches(
        /^[a-zA-Z0-9]+$/g,
        t(`Le code LNFound doit contenir uniquement des caractères alphanumériques`)
      )
      .trim()
      .length(10, t`Le code LNFound doit contenir exactement 10 caractères`)
      .required(t`Le code LNFound est obligatoire`),
  });

  useEffect(() => {
    signOut(auth).catch((error) => {
      console.error("Error during sign out:", error);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!auth.currentUser) {
        await signInAnonymously(auth);
      }
      navigate(`/${values.code.toUpperCase()}`);
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            position: 'relative'
          }}
        >
          <Typography variant="h4" gutterBottom>
            <Trans>
              Bienvenue sur LNFound!
            </Trans>
          </Typography>
          <LanguageSelector />
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            <Trans>
              La solution rapide pour retrouver vos objets perdus.
            </Trans>
          </Typography>

          <Box
            sx={{
              marginY: 2,
              height: 60,
              width: 60,
              borderRadius: "13px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(180deg, #1BE8CF 8.5%, #2652C0 100%)",
            }}
          >
            <Scan />
          </Box>

          {isNotLost ? (
            <>
              <Typography variant="h5" component="h1" gutterBottom>
                <Trans>
                  Merci pour votre visite
                </Trans>
              </Typography>
              <Typography my={5} variant="h6" gutterBottom>
                <Trans>
                  Cet objet n’est pas perdu ou n’existe pas.
                </Trans>
              </Typography>
              <Button
                style={{
                  fontWeight: "600",
                  color: "#2575C4",
                  height: 46,
                  fontSize: "17",
                  background: "#00FFE1",
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "35px",
                }}
                onClick={() => setIsNotLost(false)}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <Trans>
                  Réessayer
                </Trans>
              </Button>
            </>
          ) : (
            <>
              <Typography sx={{ marginY: 2 }} variant="h6">
                <Trans>
                  Vous avez trouvé un objet ?
                </Trans>
              </Typography>
              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{ mt: 2, width: "100%" }}
              >
                <Typography variant="subtitle2" component="div">
                  <Trans>
                    Entrez le code LNFound ci-dessous
                  </Trans>
                </Typography>
                <TextField
                  fullWidth
                  sx={{
                    input: {
                      borderRadius: 25,
                      textTransform: "uppercase",
                      backgroundColor: "white",
                    },
                  }}
                  margin="normal"
                  id="code"
                  inputProps={{
                    maxLength: 10,
                  }}
                  name="code"
                  value={formik.values.code}
                  placeholder="Ex. LNF6050LSD"
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />

                <Button
                  style={{
                    fontWeight: "600",
                    color: "#2575C4",
                    height: 46,
                    fontSize: "17",
                    background: "#00FFE1",
                    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                    borderRadius: "35px",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <Trans>
                    Envoyer
                  </Trans>
                </Button>
              </Box>
            </>
          )}

          <Typography variant="body2" sx={{ marginTop: 2, marginBottom: 2 }}>
            <Trans>
              Ou plus simple encore, téléchargez gratuitement l'application et
              conversez directement avec le propriétaire.
            </Trans>
          </Typography>
          <Socials />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Home;
