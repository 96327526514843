import { Box } from "@mui/material";

import { ReactComponent as GooglePlay } from "../../assets/googlePlay.svg";
import { ReactComponent as AppStore } from "../../assets/appStore.svg";

const Socials = () => {
  return (
    <>
      <Box
        target="_blank"
        href="https://play.google.com/store/apps/details?id=net.linksoftware.lnFound"
        sx={{
          marginY: 1,
        }}
        component="a"
      >
        <GooglePlay />
      </Box>
      <Box
        target="_blank"
        href="https://apps.apple.com/be/app/lnfound/id1642695747"
        component="a"
        sx={{
          marginBottom: 1,
        }}
      >
        <AppStore />
      </Box>
    </>
  );
};

export default Socials;
