//@ts-nocheck
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ref, getDownloadURL} from "firebase/storage";
import {signInAnonymously} from "firebase/auth";
import {useFormik} from "formik";
import * as yup from "yup";
import {v4 as uuidv4} from "uuid";
import {
  getDocs,
  query,
  doc,
  setDoc,
  where,
  collection,
  collectionGroup,
  deleteDoc,
  addDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import {Trans, useTranslation} from "react-i18next";

import PersonOutlined from "@mui/icons-material/PersonOutlined";
import FormHelperText from "@mui/material/FormHelperText";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Badge,
  Divider,
  Avatar,
  Box,
  Grid,
  Link,
} from "@mui/material";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Center from "../components/utils/Center";
import {useAppToast} from "../hooks/useAppToast";
import {db, storage, auth} from "../config/firebase";
import {ReactComponent as Check} from "../assets/Check.svg";
import {ReactComponent as Scan} from "../assets/Scan.svg";
import Socials from "../components/utils/Socials";
import LanguageSelector from "../components/utils/LanguageSelector";

export const ObjectPhoto = ({path}: any) => {
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getDownloadURL(ref(storage, path)).then((url) => {
      setUrl(url);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {loading ? (
        <CircularProgress
          size="1em"
          sx={{
            color: "white",
          }}
        />
      ) : (
        <Badge
          overlap="circular"
          anchorOrigin={{vertical: "top", horizontal: "right"}}
          badgeContent={<Check />}
        >
          <Avatar
            style={{
              border: "3px solid white",
              height: 100,
              width: 100,
            }}
            alt="Travis Howard"
            src={url}
          />
        </Badge>
      )}
    </Box>
  );
};

const ObjectFound = () => {
  let {code} = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [isNotLost, setIsNotLost] = useState<boolean>(false);
  const {t} = useTranslation();
  const toast = useAppToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!auth?.currentUser) {
          await signInAnonymously(auth);
        }
        const q = query(
          collectionGroup(db, "objects"),
          where("code", "==", code)
        );
        let querySnapshot = await getDocs(q);
        if (!querySnapshot.empty && querySnapshot.docs[0].data().hasLost) {
          setData({
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
          });
        } else {
          setIsNotLost(true);
        }
        setLoading(false);
      } catch (error) {
        // console.log("error", error);
        setLoading(false);
        setIsNotLost(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validationSchema = yup.object({
    message: yup.string().required(t`*Champ obligatoire`),
    email: yup.string().email().required(t`*Champ obligatoire`),
    hasAgreedToPrivacyPolicy: yup
      .boolean()
      .oneOf([true], t`*Vous devez accepter les termes et conditions`),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
      email: "",
      phone: "",
      hasAgreedToPrivacyPolicy: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const q = query(doc(db, "conversations", `${data.id}`));
        const querySnapshot = await getDoc(q);

        if (querySnapshot.exists()) {
          const q2 = query(collection(db, "conversations", `${data.id}`, "messages"));
          const messagesSnapshot = await getDocs(q2);

          await Promise.all(
            messagesSnapshot.docs.map(async (item) => {
              await deleteDoc(item.ref);
            })
          );

          await deleteDoc(q);
        }

        const conversationData = {
          object: {
            userId: data.userId, // user UID
            firstName: data.firstName,
            name: data.name,
            type: data.type,
            code: data.code,
          },
          unreadMessages: 0,
          founderEmail: values.email,
          lastMessageTimestamp: serverTimestamp(),
          membersIds: [auth.currentUser.uid, data.userId],
        };

        await setDoc(q, conversationData);

        const messageData = {
          _id: uuidv4(),
          createdAt: new Date().toISOString(),
          text: values.message,
          user: {
            _id: auth?.currentUser?.uid,
            avatar: null,
            name: null,
            isAnonymous: true,
          },
        };

        await addDoc(collection(db, "conversations", `${data.id}`, "messages"), messageData);

        await setDoc(
          conversationRef,
          {
            lastMessageTimestamp: serverTimestamp(),
          },
          {merge: true}
        );

        // Étape 6: Succès
        toast.success(t`Votre message a été envoyé avec succès`);
        formik.resetForm();
      } catch (error) {
        toast.error(t`Erreur lors de l'envoi du message`);
      }
    },
  });
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            "&.Mui-disabled": {
              backgroundColor: "#e4e4e4",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 5,
            marginLeft: 0,
            fontWeight: 600,
          },
        },
      },
    },
  });
  // const getMessages = async () => {
  //   try {
  //     const q2 = query(
  //       collection(db, "conversations", "t0pYsKyZ9wiEVWDqIkT7", "messages")
  //     );
  //     let querySnapshot = await getDocs(q2);
  //     console.log(querySnapshot.size);
  //     querySnapshot.forEach((doc) => {
  //       setDoc(doc.ref, {
  //         test: 40,
  //       });
  //     });
  //   } catch (error) {
  //     console.log("error testo ", error);
  //   }
  // };
  if (loading) {
    return (
      <Center>
        <CircularProgress
          sx={{
            color: "white",
          }}
        />
      </Center>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isNotLost ? (
            <>
              <Typography variant="h5" component="h1" gutterBottom>
                <Trans>
                  Merci pour votre visite
                </Trans>
              </Typography>
              <Box
                sx={{
                  marginY: 2,
                  height: 60,
                  width: 60,
                  borderRadius: "13px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(180deg, #1BE8CF 8.5%, #2652C0 100%)",
                }}
                component="div"
              >
                <Scan />
              </Box>
              <Typography variant="subtitle2" gutterBottom component="div">
                LnFound
              </Typography>

              <Typography
                sx={{
                  marginY: 2,
                  fontWeight: "700",
                  textAlign: "center",
                }}
                variant="subtitle1"
                gutterBottom
                component="h3"
              >
                <Trans>
                  L'objet n'est pas perdu ou n'a pas été déclaré perdu.
                </Trans>
              </Typography>
              <Typography
                sx={{
                  typography: {
                    xs: {
                      textAlign: "center",
                    },
                    lg: {
                      textAlign: "start",
                    },
                  },
                }}
                variant="body1"
                gutterBottom
              >
                <Trans>
                  LnFound (comme Lost and Found) l'application N°1 pour retrouver
                  rapidement vos objets perdus,gratuitement.
                </Trans>
              </Typography>
              <Socials />
            </>
          ) : (
            <Box component="form" onSubmit={formik.handleSubmit} sx={{mt: 2}}>
              <Typography
                sx={{textAlign: "center", marginBottom: 5}}
                variant="h5"
                component="h1"
                gutterBottom
              >
                <Trans>
                  Merci d’avoir retrouvé cet objet !
                </Trans>
              </Typography>
              <Grid direction="row" alignItems="center" container>
                <Grid item xs={5} lg={4}>
                  <ObjectPhoto path={data?.photo} />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Typography variant="subtitle1" component="div">
                    {data.code}
                  </Typography>
                  <Typography sx={{fontWeight: "bold"}} component="b">
                    {data.name}
                  </Typography>
                  {data?.showCity ? (
                    <Typography variant="subtitle1" component="div">
                      {data.city}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Typography sx={{marginY: 2}} variant="body1">
                <Trans>
                  Informez le propriétaire! écrivez-lui un message,en vous
                  identifiant par e-mail (Votre e-mail est tenu confidentiel).
                </Trans>
              </Typography>
              <TextField
                fullWidth
                placeholder={t`* Votre message au propriétaire... `}
                sx={{
                  input: {
                    backgroundColor: "white",
                  },
                }}
                margin="normal"
                id="message"
                name="message"
                value={formik.values.message}
                multiline
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                rows={4}
              />

              <TextField
                fullWidth
                margin="normal"
                id="email"
                placeholder={t`* Mon email`}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                fullWidth
                margin="normal"
                id="phone"
                name="phone"
                placeholder={t`Mon numéro de téléphone (facultatif)`}
                type="tel"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasAgreedToPrivacyPolicy"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#00FFE1",
                      },
                      "&.Mui-error": {
                        color: "#d32f2f",
                      },
                    }}
                    inputProps={{"aria-label": "controlled"}}
                    onChange={formik.handleChange}
                    checked={formik.values.hasAgreedToPrivacyPolicy}
                    color="primary"
                  />
                }
                label={
                  <span>
                    <Trans i18nKey="J'accepte la politique de confidentialité">
                      J'accepte la{" "}
                      <Link href="https://lnfound.com/politique-confidentialite/" target="_blank" underline="always" sx={{color: "#FFFFFF"}}>
                        politique de confidentialité
                      </Link>
                    </Trans>
                  </span>
                }
              />
              {formik.touched.hasAgreedToPrivacyPolicy &&
                formik.errors.hasAgreedToPrivacyPolicy ? (
                <FormHelperText className="Mui-error">
                  {formik.errors.hasAgreedToPrivacyPolicy}
                </FormHelperText>
              ) : null}

              {data?.showPseudo || data?.showName ? (
                <>
                  <Divider
                    sx={{
                      marginY: 2,
                      borderColor: "#FFFFFF",
                    }}
                  />
                  <Typography sx={{fontWeight: "bold"}} variant="body1">
                    Cet objet appartient à
                  </Typography>
                  <Divider
                    sx={{
                      marginY: 2,
                      borderColor: "#FFFFFF",
                    }}
                  />
                </>
              ) : null}
              {data?.showName ? (
                <>
                  <Grid
                    margin="normal"
                    direction="row"
                    alignItems="center"
                    container
                  >
                    <Grid item xs={1}>
                      <PersonOutlined />{" "}
                    </Grid>
                    <Grid item xs={4}>
                      {data?.showName && (
                        <Typography variant="subtitle">{`${data?.firstName} ${data?.lastName} `}</Typography>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : null}

              {data?.showPseudo && data?.pseudo ? (
                <Grid
                  margin="normal"
                  direction="row"
                  alignItems="center"
                  container
                >
                  <Grid item xs={1}>
                    <PersonOutlined />{" "}
                  </Grid>
                  <Grid item xs={4}>
                    {data?.showPseudo && (
                      <Typography variant="subtitle">{`${data?.pseudo}`}</Typography>
                    )}
                  </Grid>
                </Grid>
              ) : null}
              <Button
                style={{
                  fontWeight: "600",
                  color: "#2575C4",
                  height: 46,
                  fontSize: "17",
                  background: "#00FFE1",
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "35px",
                }}
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
              >
                <Trans>
                  Envoyer
                </Trans>
              </Button>
            </Box>
          )}
          <LanguageSelector />
        </Box>
      </Container>
    </ThemeProvider >
  );
};
export default ObjectFound;
